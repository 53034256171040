import { serviceDelete, serviceGet, servicePost, servicePut } from './_factory'

// learning path
const getList = (query, token) => serviceGet('/v1/learning-paths', query, token)
const getByIdOrSlug = (id, token) =>
  serviceGet(`/v1/learning-paths/${id}`, undefined, token)
const create = (data, token) => servicePost('/v1/learning-paths', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/learning-paths/${id}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/learning-paths/${id}`, undefined, token)

// progress
const getProgressList = (query, token) =>
  serviceGet('/v1/learning-paths/progresses', query, token)
const getProgressById = (id, query, token) =>
  serviceGet(`/v1/learning-paths/progresses/${id}`, query, token)

const learningPathService = {
  // learning path
  getList,
  getByIdOrSlug,
  create,
  update,
  remove,
  // progress
  getProgressList,
  getProgressById
}

export default learningPathService
