import { serviceDelete, serviceGet, servicePost, servicePut } from './_factory'

const getList = (query, token) =>
  serviceGet('/v1/review-packages', query, token)
const getById = (id, query, token) =>
  serviceGet(`/v1/review-packages/${id}`, query, token)
const getContent = (query, token) =>
  serviceGet('/v1/review-packages/contents', query, token)
const create = (data, token) => servicePost('/v1/review-packages', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/review-packages/${id}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/review-packages/${id}`, undefined, token)

const reviewPackageService = {
  getList,
  getById,
  getContent,
  create,
  update,
  remove
}

export default reviewPackageService
