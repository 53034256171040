import axios from 'axios'

import {
  getEndpointUrl,
  serviceDelete,
  serviceGet,
  servicePost,
  servicePut
} from './_factory'

// bootcamp
const getByIdOrSlug = (idOrSlug, token) =>
  serviceGet(`/v1/bootcamps/${idOrSlug}`, undefined, token)
const getList = (query, token) => serviceGet('/v1/bootcamps', query, token)
const create = (data, token) => servicePost('/v1/bootcamps', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/bootcamps/${id}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/bootcamps/${id}`, undefined, token)

// bootcamp batch
const getBatchById = (id, query, token) =>
  serviceGet(`/v1/bootcamps/batches/${id}`, query, token)
const getBatchList = (query, token) =>
  serviceGet('/v1/bootcamps/batches', query, token)
const createBatch = (data, token) =>
  servicePost('/v1/bootcamps/batches', data, token)
const updateBatch = (id, data, token) =>
  servicePut(`/v1/bootcamps/batches/${id}`, data, token)
const deleteBatch = (id, token) =>
  serviceDelete(`/v1/bootcamps/batches/${id}`, undefined, token)

// bootcamp participant
const getParticipantById = (id, query, token) =>
  serviceGet(`/v1/bootcamps/participants/${id}`, query, token)
const getParticipantList = (query, token) =>
  serviceGet('/v1/bootcamps/participants', query, token)
const getParticipantCount = (query, token) =>
  serviceGet('/v1/bootcamps/participants/count', query, token)
const getParticipantStats = (query, token) =>
  serviceGet('/v1/bootcamps/participants/stats', query, token)
const createUpdateParticipant = (data, token) =>
  servicePost('/v1/bootcamps/participants', data, token)
const updateParticipantCertificateType = (participantId, data, token) =>
  servicePost(
    `/v1/bootcamps/participants/${participantId}/certificate-type`,
    data,
    token
  )

// export
const exportParticipantToCSV = (query, token) =>
  axios.get(getEndpointUrl('/v1/bootcamps/participants/csv'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    responseType: 'blob',
    params: query
  })

const bootcampService = {
  // bootcamp
  getByIdOrSlug,
  getList,
  create,
  update,
  remove,
  // batch
  getBatchById,
  getBatchList,
  createBatch,
  updateBatch,
  deleteBatch,
  // participant
  getParticipantById,
  getParticipantList,
  getParticipantCount,
  getParticipantStats,
  createUpdateParticipant,
  updateParticipantCertificateType,
  // export
  exportParticipantToCSV
}

export default bootcampService
