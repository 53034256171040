// react
import { createContext, useEffect, useMemo, useReducer } from 'react'
// firebase
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  GoogleAuthProvider,
  confirmPasswordReset,
  verifyPasswordResetCode,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword
} from 'firebase/auth'
// service
import { userService } from '~/services'
// hooks
import { useSnackbar } from 'notistack'
// libs
import { recursivelyProcessUserToken } from '~/libs/userHelper'
import sleep from '~/libs/sleep'

// ----------------------------------------------------------------------

const trimSensitiveUserInfo = (user) => ({
  // auth related
  id: user?.uid,
  email: user?.email,
  role: user?.role ?? 'student',
  // from firebase auth, but generally unused
  photoURL: user?.photoURL,
  // from firebase auth
  name: user?.name,
  // from MySkill Database
  phone: user?.phone,
  dateOfBirth: user?.dateOfBirth,
  gender: user?.gender,
  address: user?.address,
  resumeURL: user?.resumeURL,
  profession: user?.profession,
  targetOpportunity: user?.targetOpportunity,
  // flags
  isEmailVerified: user?.isEmailVerified,
  isAllowCampaignEmail: user?.isAllowCampaignEmail
})

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  }

  if (action.type === 'RELOAD_PROFILE') {
    const { user } = action.payload

    return {
      ...state,
      user
    }
  }

  return state
}

const FirebaseAuthContext = createContext({
  ...initialState,
  method: 'firebase',
  getToken: () => Promise.resolve(null),
  getRole: () => Promise.resolve(null),
  getAccessLevel: () => Promise.resolve(null),
  authorizeService: Promise.resolve(null),
  // login
  login: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFacebook: () => Promise.resolve(),
  // register
  register: () => Promise.resolve(),
  registerWithGoogle: () => Promise.resolve(),
  registerWithFacebook: () => Promise.resolve(),
  // logout
  logout: () => Promise.resolve(),
  // profile
  updateProfile: () => Promise.resolve(),
  reloadProfile: () => Promise.resolve(),
  // reset password
  requestResetPassword: () => Promise.resolve(),
  verifyResetPassword: () => Promise.resolve(),
  confirmResetPassword: () => Promise.resolve()
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {FirebaseApp} props.firebaseApp
 * @param {ReactNode} props.children
 * @returns {JSX.Element}
 */
function FirebaseAuthProvider({ firebaseApp, children }) {
  // firebase
  const auth = useMemo(() => getAuth(firebaseApp), [firebaseApp])
  const googleProvider = useMemo(() => {
    const provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')

    return provider
  }, [])
  const facebookProvider = useMemo(() => {
    const provider = new FacebookAuthProvider()
    return provider
  }, [])

  // context state
  const { enqueueSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () =>
      // handle auth state change
      onAuthStateChanged(auth, async (firebaseUser) => {
        if (firebaseUser) {
          // fetch profile from MySkill Database
          // use exponential backoff when failed,
          // initial delay of 1s on first retry, up to 5 retry
          // usefull when user just registered and profile is not yet created
          const fetchedUserProfile = await fetchProfile()

          // notify user
          if (!fetchedUserProfile) {
            enqueueSnackbar(
              'Terjadi masalah dalam mengambil data pengguna, beberapa fitur mungkin tidak akan bekerja dengan sesuai.',
              { variant: 'warning' }
            )
          }

          // still dispatch user login even if combinedUserProfile is null
          // user will be able to access some feature, but not do transaction
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: trimSensitiveUserInfo(fetchedUserProfile ?? firebaseUser)
            }
          })
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          })
        }
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  // login
  const login = (email, password) =>
    signInWithEmailAndPassword(auth, email, password)
  const loginWithGoogle = () => signInWithPopup(auth, googleProvider)
  const loginWithFacebook = () => signInWithPopup(auth, facebookProvider)

  // register
  const register = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password)
  const registerWithGoogle = () => signInWithPopup(auth, googleProvider)
  const registerWithFacebook = () => signInWithPopup(auth, facebookProvider)

  // password reset
  const requestResetPassword = (email) => sendPasswordResetEmail(auth, email)
  const confirmResetPassword = (code, newPassword) =>
    confirmPasswordReset(auth, code, newPassword)
  const verifyResetPassword = (code) => verifyPasswordResetCode(auth, code)

  // change credential (emai, password)
  const changeEmail = async (newEmail) => {
    const idToken = await auth.currentUser.getIdToken()

    await userService.updateEmail(
      auth.currentUser.uid,
      { email: newEmail },
      idToken
    )
  }
  const changePassword = (newPassword) =>
    updatePassword(auth.currentUser, newPassword)

  // profile
  const updateProfile = async (newProfile) => {
    // update myskill profile
    const idToken = await auth.currentUser.getIdToken()

    await userService.update(auth.currentUser.uid, newProfile, idToken)
  }

  const fetchProfile = async () => {
    let retryCount = 0
    let newUserProfile = null

    do {
      await sleep(retryCount * 1000)

      try {
        const [idToken, customClaims] = await Promise.all([
          auth.currentUser.getIdToken(),
          auth.currentUser.getIdTokenResult().then((res) => res.claims)
        ])
        const mySkillProfile = await userService.getById(
          auth.currentUser.uid,
          idToken
        )

        newUserProfile = {
          ...auth.currentUser,
          ...mySkillProfile.data.results,
          role: customClaims.role
        }
      } catch (_) {
        retryCount += 1
      }
    } while (!newUserProfile && retryCount <= 5)

    return newUserProfile
  }

  const reloadProfile = async () => {
    const idToken = await auth.currentUser.getIdToken()

    // reload myskill profile
    const mySkillProfile = await userService.getById(
      auth.currentUser.uid,
      idToken
    )

    // reload firebase profile
    await auth.currentUser.reload()

    dispatch({
      type: 'RELOAD_PROFILE',
      payload: {
        user: trimSensitiveUserInfo({
          ...auth.currentUser,
          ...mySkillProfile.data.results
        })
      }
    })
  }

  // reauthenticate
  const reauthenticateWithPassword = async (password) => {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    )

    await reauthenticateWithCredential(auth.currentUser, credential)
  }

  // logout
  const logout = () => signOut(auth)

  // token
  const getToken = async () => {
    if (!auth?.currentUser) return null

    return await auth.currentUser.getIdToken()
  }

  // role
  const getRole = async () => {
    if (!auth?.currentUser) return null

    const idTokenResult = await auth.currentUser.getIdTokenResult()

    return idTokenResult?.claims?.role ?? null
  }

  const getAccessLevel = async () => {
    if (!auth?.currentUser) return null

    const idTokenResult = await auth.currentUser.getIdTokenResult()

    return idTokenResult?.claims?.accessLevel ?? null
  }

  /**
   * Wrapper to call service function with access token
   * @param {()=>Promise<*>}   serviceFunction - service function to be called
   * @param {string} errorOnNoToken  - whether to throw error when token is unavailable, default to false
   * @returns {Promise<*>} whatever return that serviceFunction give
   */
  const authorizeService =
    (serviceFunction, errorIfTokenUnavailable = false) =>
    async (...args) => {
      const token = await getToken()

      if (!token && errorIfTokenUnavailable)
        throw new Error('Unauthorized access to service')

      // replace `$user` token(s) in args with current user data
      const [_, parsedArgs] = recursivelyProcessUserToken(state.user, args)

      // make sure arguments length is correct, and token is the last argument
      const appliedArgs = [...Array(serviceFunction.length)].map(
        (_funcArg, i) => parsedArgs[i] ?? undefined
      )
      appliedArgs[serviceFunction.length - 1] = token

      return serviceFunction(...appliedArgs)
    }

  return (
    <FirebaseAuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        // user auth
        getToken,
        getRole,
        getAccessLevel,
        authorizeService,
        // login
        login,
        loginWithGoogle,
        loginWithFacebook,
        // register
        register,
        registerWithGoogle,
        registerWithFacebook,
        // logout
        logout,
        // profile
        updateProfile,
        reloadProfile,
        // password reset
        requestResetPassword,
        verifyResetPassword,
        confirmResetPassword,
        // reauthenthicate
        reauthenticateWithPassword,
        // change credential
        changeEmail,
        changePassword
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  )
}

export { FirebaseAuthContext, FirebaseAuthProvider }
