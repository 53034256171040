// @mui
import { Box, Card, Grid, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
// components
import NextImage from './NextImage'
import TextMaxLine from './TextMaxLine'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.position
 * @param {string} props.company
 * @param {string} props.avatar
 * @param {()=> void} props.onClose
 * @returns {JSX.Element}
 */
export default function ImpactTestimonyCard({
  name,
  position,
  company,
  avatar,
  onClose
}) {
  return (
    <Card>
      <Box padding={1.5}>
        <Grid
          container
          spacing={1.5}
          alignItems='center'
          flexWrap='nowrap'
        >
          <Grid
            item
            xs='auto'
          >
            <NextImage
              src={avatar}
              alt={name}
              nextHeight={64}
              nextWidth={64}
              sx={{
                width: '4rem',
                height: '4rem',
                borderRadius: 1
              }}
            />
          </Grid>

          <Grid
            item
            xs
          >
            <TextMaxLine
              line={1}
              sx={{
                fontSize: '0.9rem',
                fontWeight: 'bold',
                marginBottom: 0.25
              }}
            >
              {name}
            </TextMaxLine>

            <TextMaxLine
              line={2}
              sx={{ fontSize: 'small', lineHeight: 1.25 }}
            >
              Diterima sebagai <b>{position}</b> di <b>{company}</b>.
            </TextMaxLine>
          </Grid>

          {onClose && (
            <Grid
              item
              xs='auto'
              alignSelf='flex-start'
            >
              <IconButton
                onClick={onClose}
                size='small'
              >
                <Close fontSize='inherit' />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  )
}
