import avatarGeneralPaksi from '~/assets/testimony/general/paksi.png'
import avatarGeneralRyan from '~/assets/testimony/general/ryan.png'
import avatarGeneralFaizal from '~/assets/testimony/general/faizal.png'
import avatarGeneralMandiri from '~/assets/testimony/general/mandiri.png'
import avatarGeneralArdi from '~/assets/testimony/general/ardi.png'
import avatarGeneralRizki from '~/assets/testimony/general/rizki.png'
import avatarGeneralIhsan from '~/assets/testimony/general/ihsan.png'
import avatarGeneralKemkominfo from '~/assets/testimony/general/kemkominfo.png'
import avatarGeneralRahma from '~/assets/testimony/general/rahma.png'
import avatarGeneralParagon from '~/assets/testimony/general/paragon.png'
import avatarGeneralBoma from '~/assets/testimony/general/boma.png'

import avatarImpactPaksi from '~/assets/testimony/impact/paksi.png'
import avatarImpactArkhan from '~/assets/testimony/impact/arkhan.png'
import avatarImpactDave from '~/assets/testimony/impact/dave.png'
import avatarImpactLatifah from '~/assets/testimony/impact/latifah.png'
import avatarImpactEvira from '~/assets/testimony/impact/evira.png'
import avatarImpactDzul from '~/assets/testimony/impact/dzul.png'
import avatarImpactRajib from '~/assets/testimony/impact/rajib.png'
import avatarImpactYusril from '~/assets/testimony/impact/yusril.png'
import avatarImpactHamdiki from '~/assets/testimony/impact/hamdiki.png'
import avatarImpactBrian from '~/assets/testimony/impact/brian.png'
import avatarImpactDedy from '~/assets/testimony/impact/dedy.png'
import avatarImpactArya from '~/assets/testimony/impact/arya.png'
import avatarImpactFauzan from '~/assets/testimony/impact/fauzan.png'
import avatarImpactRizqullah from '~/assets/testimony/impact/rizqullah.png'
import avatarImpactRizki from '~/assets/testimony/impact/rizki.png'

import posterMemberAllev from '~/assets/testimony/member/b-allev.png'
import posterMemberArkhan from '~/assets/testimony/member/b-arkhan.png'
import posterMemberArya from '~/assets/testimony/member/b-arya.png'
import posterMemberBrian from '~/assets/testimony/member/b-brian.png'
import posterMemberDave from '~/assets/testimony/member/b-dave.png'
import posterMemberDzul from '~/assets/testimony/member/b-dzul.png'
import posterMemberHamdiki from '~/assets/testimony/member/b-hamdiki.png'
import posterMemberNico from '~/assets/testimony/member/b-nico.png'
import posterMemberPaksi from '~/assets/testimony/member/b-paksi.png'

import posterMemberDedy from '~/assets/testimony/member/cm-dedy.png'
import posterMemberFauzan from '~/assets/testimony/member/cm-fauzan.png'
import posterMemberGalih from '~/assets/testimony/member/cm-galih.png'
import posterMemberHafidha from '~/assets/testimony/member/cm-hafidha.png'
import posterMemberHana from '~/assets/testimony/member/cm-hana.png'
import posterMemberRajib from '~/assets/testimony/member/cm-rajib.png'
import posterMemberYusril from '~/assets/testimony/member/cm-yusril.png'

import posterMemberAgustinus from '~/assets/testimony/member/el-agustinus.png'
import posterMemberDzikra from '~/assets/testimony/member/el-dzikra.png'
import posterMemberFadli from '~/assets/testimony/member/el-fadli.png'
import posterMemberIndah from '~/assets/testimony/member/el-indah.png'
import posterMemberLatifah from '~/assets/testimony/member/el-latifah.png'
import posterMemberRizqullah from '~/assets/testimony/member/el-rizqullah.png'

import posterMemberAnggita from '~/assets/testimony/member/rc-anggita.png'
import posterMemberHadi from '~/assets/testimony/member/rc-hadi.png'
import posterMemberNovela from '~/assets/testimony/member/rc-novela.png'
import posterMemberOri from '~/assets/testimony/member/rc-ori.png'
import posterMemberRizki from '~/assets/testimony/member/rc-rizki.png'

// ----------------------------------------------------------------------

export const GENERAL_TESTIMONY = Object.freeze([
  {
    type: 'user',
    avatar: avatarGeneralPaksi,
    name: 'Paksi Cahyo Baskoro',
    description: 'Full time copywriter Bank DBS',
    testimony:
      'Aku dapat banyak banget ilmu & praktik yang jumpstart karier. Dari hukum, ke copywriter. Tapi karena ikut Bootcamp MySkill, aku menjadi Copywriter di DBS Bank.'
  },
  {
    type: 'user',
    avatar: avatarGeneralRyan,
    name: 'Ryan Dwana',
    description: 'Business Director at Initiative - Global Media Agency',
    testimony:
      'Materi ini saya rancang dari 10 tahun lebih berkecimpung di multinational marketing agency dan tech companies. Terstruktur dan sesuai standar industri terkini. Yuk mulai menyelami materinya!'
  },
  {
    type: 'user',
    avatar: avatarGeneralFaizal,
    name: 'Faizal Alwan',
    description: 'UIUX Design at PT. Esensi Solusi Buana (ESB)',
    testimony:
      'Jujur, ini pertama ikut Bootcamp UI/UX. Saya langsung tertarik dengan Bootcamp MySkill. Setelah selesai, saya dapat Fultime Job as a UI/UX Designer di ESB. Thank you MySkill!'
  },
  {
    type: 'corporate',
    name: 'Bank Mandiri',
    avatar: avatarGeneralMandiri,
    testimony:
      'Banyak insight & mudah dipahami. Penjelasan materi dilakukan secara interaktif menggunakan kasus yang relate dengan pekerjaan.',
    description: 'Corporate Client'
  },
  {
    type: 'user',
    avatar: avatarGeneralArdi,
    name: 'Moch. Ardi',
    description: 'Business Analyst Manager at Carvil',
    testimony:
      'Materi ini dibuat berdasarkan case yang sering dihadapi di dunia pekerjaan yang sudah saya geluti bertahun-tahun. Sehingga potensinya besar banget buat kamu semua untuk faham secara mendalam. Yuk ikutin satu per satu!'
  },

  {
    type: 'user',
    avatar: avatarGeneralRizki,
    name: 'Rizki Utami',
    description: 'Alumni IELTS Bootcamp',
    testimony:
      'The class is super intensive and interactive, kurikulumnya rapi, tutornya & bisa menjangkau semua. Setelah mengikuti Bootcamp IELTS,  saya mendapat score 7 overall.'
  },
  {
    type: 'user',
    avatar: avatarGeneralIhsan,
    name: 'Ihsan Nur',
    description: 'Fulbright Scholarship Awardee at Ohio University, USA',
    testimony:
      'Materi ini saya rancang berdasarkan pengalaman mengajar English Test Preparation selama 6 tahun. Pelajaran yang disampaikan semoga bisa meningkatkan pemahamanmu dan menjadi guidelines!'
  },
  {
    type: 'corporate',
    name: 'Kemkominfo',
    avatar: avatarGeneralKemkominfo,
    testimony:
      'Materi berbobot dijelaskan dengan ringan. Sangat jelas, insightful dan disampaikan dengan sangat bagus.',
    description: 'Corporate Client'
  },

  {
    type: 'user',
    avatar: null,
    name: 'Ori Meviani',
    description: 'Alumni Review MySkill',
    testimony:
      'CV review MySkill sukses membantu saya mendapatkan pekerjaan di Kemenkes bagian digital transformation. Terima kasih banyak MySkill!'
  },
  {
    type: 'user',
    avatar: avatarGeneralRahma,
    name: 'Rahma Yustika',
    description: 'Head of People Operations at Pinhome',
    testimony:
      'Sangat senang bisa berkontribusi. Saya banyak mengaitkannya dengan pengalaman nyata yang telah saya jalankan selama bertahun-tahun di bidang HR. Semoga bermanfaat bagi teman teman semua!'
  },
  {
    type: 'user',
    avatar: null,
    name: 'Muhammad Rizki Maulana',
    description: 'Alumni Review MySkill',
    testimony:
      'CV review MySkill bagus banget! HRD sangat responsif dan detail. Saya bisa memperbaiki sekaligus improve cv saya. Akhirnya bisa lolos intern di salah satu Multinational FMCG, walau saya bukan dari kampus ternama. Terima kasih MySkill!'
  },
  {
    type: 'corporate',
    name: 'Paragon',
    avatar: avatarGeneralParagon,
    testimony:
      'Penyampaiannya jelas, highlight yang penting disampaikan berulang kali. Sangat baik penjelasannya dengan contoh real, jadi bisa dibayangkan secara langsung.',
    description: 'Corporate Client'
  },
  {
    type: 'user',
    avatar: avatarGeneralBoma,
    name: 'Boma Septiago',
    description: 'Principal Talent Acquisition at a Decacorn Startup',
    testimony:
      'Materi ini dirancang dari pengalaman saya sebagai HR selama sekitar 9 tahun dalam menyeleksi ribuan kandidat dan membantu berkembangnya talenta di perusahaan. Semoga bisa membantu mencapai karir impianmu!'
  }
])

export const IMPACT_TESTIMONY = Object.freeze([
  {
    name: 'Paksi Cahyo Baskoro',
    position: 'Copywriter',
    company: 'DBS Bank Indonesia',
    avatar: avatarImpactPaksi
  },
  {
    name: 'M. Arkhan Doohan',
    position: 'Data Analyst',
    company: 'United Tractors',
    avatar: avatarImpactArkhan
  },
  {
    name: 'Dave Emmanuel',
    position: 'Business Development Analyst',
    company: 'Shopee',
    avatar: avatarImpactDave
  },
  {
    name: 'Latifah NH Putri',
    position: 'HR Generalist',
    company: 'TIX ID',
    avatar: avatarImpactLatifah
  },
  {
    name: 'Evira Aurellia',
    position: 'Staff Finance',
    company: 'Basic Pratama Production',
    avatar: avatarImpactEvira
  },
  {
    name: 'Dzul Fiqram Nur',
    position: 'Content Specialist',
    company: 'Amar Bank',
    avatar: avatarImpactDzul
  },
  {
    name: 'M. Rajib Abdul G.',
    position: 'Business Development Executive',
    company: 'PT. Dachin Etech Global',
    avatar: avatarImpactRajib
  },
  {
    name: 'Yusril Azmi',
    position: 'Social Media Specialist',
    company: 'Digitaluna.id',
    avatar: avatarImpactYusril
  },
  {
    name: 'Hamdiki Ainur',
    position: 'Associate Acquisition',
    company: 'ADA',
    avatar: avatarImpactHamdiki
  },
  {
    name: 'Brian Marco',
    position: 'Communications Designer',
    company: 'C+C&Co',
    avatar: avatarImpactBrian
  },
  {
    name: 'Dedy Setiyawan',
    position: 'Digital Marketing',
    company: 'ASTRNT Technologies',
    avatar: avatarImpactDedy
  },
  {
    name: 'Arya Dharma',
    position: 'Digital Marketing Specialist',
    company: 'Imuni.id',
    avatar: avatarImpactArya
  },
  {
    name: 'M. Fauzan Labib',
    position: 'Performance Marketing',
    company: 'Home Appliances Company',
    avatar: avatarImpactFauzan
  },
  {
    name: 'Muhammad Rizqullah',
    position: 'Digital Marketing Intern',
    company: 'Erajaya Swasembada',
    avatar: avatarImpactRizqullah
  },
  {
    name: 'Muhammad Rizki M.',
    position: 'Technical Intern',
    company: 'PT. Nestle Indonesia',
    avatar: avatarImpactRizki
  }
])

export const HOMEPAGE_TESTIMONY = Object.freeze([
  {
    alt: 'Dzul Fiqram Nur - SEO Specialist PT IDStar',
    image: posterMemberDzul,
    storyURL: 'https://www.instagram.com/p/CvFCeTyJfiS/'
  },
  {
    alt: 'Hana Annisa - Sales Management Trainee Youvit',
    image: posterMemberHana,
    storyURL: 'https://www.instagram.com/p/CsbYh09pItR/'
  },
  {
    alt: 'Muhammad Rizqullah Performance - Marketing Intern Jakmall',
    image: posterMemberRizqullah,
    storyURL: 'https://www.instagram.com/p/CsjC_iFJZ1r/'
  },
  {
    alt: 'Novela Dela - Graphic Designer Bank Kalsel',
    image: posterMemberNovela,
    storyURL: 'https://www.instagram.com/p/CwPsiVRpqsc/'
  },
  {
    alt: 'Allev Hidayatulloh - Owner @kontrakanmalang.id',
    image: posterMemberAllev,
    storyURL: 'https://www.instagram.com/p/CwfULHvpKIg/'
  },
  {
    alt: 'M. Arkhan Doohan - CSR Data Management System',
    image: posterMemberArkhan,
    storyURL: 'https://www.instagram.com/p/CtY67ajpe9p/'
  },
  {
    alt: 'Indah - Punya 230K Followers',
    image: posterMemberIndah,
    storyURL: 'https://www.instagram.com/p/CxKzqk5JOce/'
  },
  {
    alt: 'Hadi Kurniadi - B2C Operation Stuff',
    image: posterMemberHadi,
    storyURL: 'https://www.instagram.com/p/CuO80MPpOw6/'
  },
  {
    alt: 'Galih Nur Ammalia - Digital Marketing Specialist Ciputra',
    image: posterMemberGalih,
    storyURL: 'https://www.instagram.com/p/Cxu-COMpqLF/'
  },
  {
    alt: 'Nico Ferrari - CMO Media Lab',
    image: posterMemberNico,
    storyURL: 'https://www.instagram.com/p/Cu_37yWJr1N/'
  }
])

export const BOOTCAMP_TESTIMONY = Object.freeze([
  {
    alt: 'Paksi Cahyo Baskoro - Copywriter DBS Bank Indonesia',
    image: posterMemberPaksi,
    storyURL: 'https://www.instagram.com/p/CvHmvvZp9yQ/'
  },
  {
    alt: 'Allev Hidayatulloh - Owner @kontrakanmalang.id',
    image: posterMemberAllev,
    storyURL: 'https://www.instagram.com/p/CwfULHvpKIg/'
  },
  {
    alt: 'M. Arkhan Doohan - CSR Data Management System',
    image: posterMemberArkhan,
    storyURL: 'https://www.instagram.com/p/CtY67ajpe9p/'
  },
  {
    alt: 'Dzul Fiqram Nur - SEO Specialist PT IDStar',
    image: posterMemberDzul,
    storyURL: 'https://www.instagram.com/p/CvFCeTyJfiS/'
  },
  {
    alt: 'Dave Emmanuel - Business Development Analyst Shopee',
    image: posterMemberDave,
    storyURL: 'https://www.instagram.com/p/CvPTkeDJleM/'
  },
  {
    alt: 'Brian Marco - Communications Executive C+C&Co',
    image: posterMemberBrian,
    storyURL: 'https://www.instagram.com/p/Cs02HQIpRFG/'
  },
  {
    alt: 'Nico Ferrari - CMO Media Lab',
    image: posterMemberNico,
    storyURL: 'https://www.instagram.com/p/Cu_37yWJr1N/'
  },
  {
    alt: 'Hamdiki Ainur - Associate Acquisition ADA',
    image: posterMemberHamdiki,
    storyURL: 'https://www.instagram.com/p/CtG2_r5ptv9/'
  },
  {
    alt: 'Arya Dharma - Digital Marketing Specialist Imuni',
    image: posterMemberArya,
    storyURL: 'https://www.instagram.com/p/CvKLNeIpABN/'
  }
])

export const MENTORING_CAREER_TESTIMONY = Object.freeze([
  {
    alt: 'Dedy Setiawan - Digital Marketing Astronaut',
    image: posterMemberDedy,
    storyURL: 'https://www.instagram.com/p/Cut2Xc3J9E2/'
  },
  {
    alt: 'M. Fauzan Labib - Performance Marketing',
    image: posterMemberFauzan,
    storyURL: 'https://www.instagram.com/p/CwxKhJNJdWU/'
  },
  {
    alt: 'Hafidha Biantoro - Project Admin',
    image: posterMemberHafidha,
    storyURL: 'https://www.instagram.com/p/Ct87Ot9pixg/'
  },
  {
    alt: 'Hana Annisa - Sales Management Trainee Youvit',
    image: posterMemberHana,
    storyURL: 'https://www.instagram.com/p/CsbYh09pItR/'
  },
  {
    alt: 'M. Rajib Abdul Ghani - Business Development Executive DAICHIN',
    image: posterMemberRajib,
    storyURL: 'https://www.instagram.com/p/CsQ8KXtJTar/'
  },
  {
    alt: 'Yusril Azmi - Digital Marketing Specialist Digitaluna.id',
    image: posterMemberYusril,
    storyURL: 'https://www.instagram.com/p/CvXJ5oOJ7qe/'
  }
])

export const ELEARNING_TESIMONY = Object.freeze([
  {
    alt: 'Latifah NH Putri - HR Generalist TIX ID',
    image: posterMemberLatifah,
    storyURL: 'https://www.instagram.com/p/Cw2Uf2rpxO4/'
  },
  {
    alt: 'Muhammad Rizqullah Performance - Marketing Intern Jakmall',
    image: posterMemberRizqullah,
    storyURL: 'https://www.instagram.com/p/CsjC_iFJZ1r/'
  },
  {
    alt: 'Indah - Punya 230K Followers',
    image: posterMemberIndah,
    storyURL: 'https://www.instagram.com/p/CxKzqk5JOce/'
  },
  {
    alt: 'Dzikra Yuhasyra - Freelancer',
    image: posterMemberDzikra,
    storyURL: 'https://www.instagram.com/p/CtjUdkepHiQ/'
  },
  {
    alt: 'Ahmad Fadli Nurrachman - Freelance Content Creator',
    image: posterMemberFadli,
    storyURL: 'https://www.instagram.com/p/CwZ_zV2JPPQ/'
  },
  {
    alt: 'Agustinus Lanang Pamuji - Magang Web Administrator',
    image: posterMemberAgustinus,
    storyURL: 'https://www.instagram.com/p/Cw7GGu2pLbu/'
  }
])

export const REVIEW_CV_TESTIMONY = Object.freeze([
  {
    alt: 'Hadi Kurniadi - B2C Operation Stuff',
    image: posterMemberHadi,
    storyURL: 'https://www.instagram.com/p/CuO80MPpOw6/'
  },
  {
    alt: 'Ori Meivani - Digital Transformation Kemenkes',
    image: posterMemberOri,
    storyURL: 'https://www.instagram.com/p/Ctq749YpPfP/'
  },
  {
    alt: 'Anggito Setya Ichsan - Environmental Engineer',
    image: posterMemberAnggita,
    storyURL: 'https://www.instagram.com/p/CwckjiVpnLi/'
  },
  {
    alt: 'Novela Dela - Graphic Designer Bank Kalsel',
    image: posterMemberNovela,
    storyURL: 'https://www.instagram.com/p/CwPsiVRpqsc/'
  },
  {
    alt: 'M. Rizki Maulana - Intern Multinational FMCG',
    image: posterMemberRizki,
    storyURL: 'https://www.instagram.com/p/CtRLiNYpu7T/'
  }
])
