import {
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost,
  servicePut
} from './_factory'

// course
const getByIdOrSlug = (id, query, token) =>
  serviceGet(`/v1/courses/${id}`, query, token)
const getList = (query, token) => serviceGet('/v1/courses', query, token)

const create = (data, token) => servicePost('/v1/courses', data, token)
const update = (id, data, token) => servicePut(`/v1/courses/${id}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/courses/${id}`, undefined, token)

const updateVideo = (id, data, token) =>
  servicePut(`/v1/courses/${id}/videos`, data, token)
const updatePreTest = (id, data, token) =>
  servicePut(`/v1/courses/${id}/pre-test`, data, token)
const updatePostTest = (id, data, token) =>
  servicePut(`/v1/courses/${id}/post-test`, data, token)

const getVideoOTP = (courseId, videoId, token) =>
  serviceGet(`/v1/courses/${courseId}/videos/${videoId}/otp`, undefined, token)

// video audience
const createVideoAudience = (data, token) =>
  servicePost('/v1/courses/video-audiences', data, token)
const updateVideoAudience = (id, data, token) =>
  servicePatch(`/v1/courses/video-audiences/${id}`, data, token)

// bookmark or saved course
const getSavedList = (query, token) =>
  serviceGet('/v1/courses/saved', query, token)
const createSave = (data, token) =>
  servicePost('/v1/courses/saved', data, token)
const removeSave = (id, token) =>
  serviceDelete(`/v1/courses/saved/${id}`, undefined, token)

// progress
const getProgressList = (query, token) =>
  serviceGet('/v1/courses/progresses', query, token)
const getProgressById = (id, query, token) =>
  serviceGet(`/v1/courses/progresses/${id}`, query, token)
const patchProgress = (id, data, token) =>
  servicePatch(`/v1/courses/progresses/${id}`, data, token)
const postQuizAttempt = (data, token) =>
  servicePost('/v1/courses/progresses/quiz-attempts', data, token)
const postCompletedVideo = (data, token) =>
  servicePost('/v1/courses/progresses/completed-videos', data, token)

// portofolio
const getPortofolioList = (query, token) =>
  serviceGet('/v1/courses/portofolios', query, token)
const getPortofolioById = (id, token) =>
  serviceGet(`/v1/courses/portofolios/${id}`, undefined, token)
const postPortofolio = (data, token) =>
  servicePost('/v1/courses/portofolios', data, token)
const putPortofolio = (id, data, token) =>
  servicePut(`/v1/courses/portofolios/${id}`, data, token)

const courseService = {
  // course
  getByIdOrSlug,
  getList,
  create,
  update,
  remove,
  //
  updateVideo,
  updatePreTest,
  updatePostTest,
  //
  getVideoOTP,
  // progress
  getProgressList,
  getProgressById,
  patchProgress,
  postQuizAttempt,
  postCompletedVideo,
  // bookmark or saved course
  getSavedList,
  createSave,
  removeSave,
  // video audience
  createVideoAudience,
  updateVideoAudience,
  // portofolio
  getPortofolioList,
  getPortofolioById,
  postPortofolio,
  putPortofolio
}

export default courseService
